import React from 'react';
import PropTypes from 'prop-types';
import { TiTicket } from 'react-icons/ti';
import {
  FiBox,
  FiSmartphone,
  FiSidebar,
  FiArrowRight,
  FiShoppingBag,
  FiBook,
  FiTrendingUp,
  FiGrid,
  FiMapPin,
  FiExternalLink,
} from 'react-icons/fi';

import Card from 'components/card';
import Title from 'components/title/title';
import Button from 'components/button/button';

import { Header, Container, LinkText } from './features.css';

const iconSwitch = icon => {
  switch (icon) {
    case 'FiBox':
      return <FiBox size="24px" color="573bee" className="mr-3" />;
    case 'FiSmartphone':
      return <FiSmartphone size="24px" color="573bee" className="mr-3" />;
    case 'FiSidebar':
      return <FiSidebar size="24px" color="573bee" className="mr-3" />;
    case 'FiShoppingBag':
      return <FiShoppingBag size="24px" color="573bee" className="mr-3" />;
    case 'FiBook':
      return <FiBook size="24px" color="573bee" className="mr-3" />;
    case 'FiTrendingUp':
      return <FiTrendingUp size="24px" color="573bee" className="mr-3" />;
    case 'TiTicket':
      return <TiTicket size="24px" color="573bee" className="mr-3" />;
    case 'FiGrid':
      return <FiGrid size="24px" color="573bee" className="mr-3" />;
    case 'FiMapPin':
      return <FiMapPin size="24px" color="573bee" className="mr-3" />;
    default:
      return null;
  }
}
const Features = ({ feature }) => {
  const { title, features } = feature;

  return (
    <>
      <Header>
        <Title as="h2">{title}</Title>
      </Header>
      <Container>
        {(features || []).map(f => {
          const { icon, headline, content, action, isRedirect = false } = f;
          return (
            <Card key={headline}>
              <div>
                <div className="d-flex flex-row align-items-center mb-4">
                  {iconSwitch(icon)}
                  <h3 className="m-0">{headline}</h3>
                </div>
                <p className="mb-5">{content}</p>
              </div>

              {!isRedirect ? (
                <Button
                  className="button--text d-flex flex-row align-items-center"
                  link={action}
                >
                  <span className="flex mt-1 mr-3">Learn more</span>
                  <FiArrowRight size="24px" />
                </Button>
              ) : (
                <>
                  <LinkText href={action} target="_blank" rel="noreferrer">
                    <span style={{ display: 'block' }} className="mt-1 mr-3">
                      Go to site
                    </span>
                    <FiExternalLink size="24px" />
                  </LinkText>
                </>
              )}
            </Card>
          );
        })}
      </Container>
    </>
  );
};

Features.propTypes = {
  feature: PropTypes.object.isRequired,
};

export default Features;
