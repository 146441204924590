import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  margin: 50px auto 100px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  p {
    margin-bottom: 0;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
  }
`;

export const Header = styled.div`
  margin: 50px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const LinkText = styled.a`
  display: flex;
  align-items: center;
  justify-content: between;
  margin: 0;
  padding: 10px 8px 10px 16px;
  background: transparent;

  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  color: #573bee;
  transition: all 0.3s ease;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background: #ecf5ff;
      text-decoration: none;
    }
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &[disabled] {
    background: #ecf5ff;
    opacity: 0.5;
    cursor: default;
    &:hover {
      box-shadow: none;
      transform: scale(1);
    }
  }
`;
