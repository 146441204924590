import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import Cta from 'components/cta';
import Hero from 'components/hero';
import Features from 'components/features';
import Benefits from 'components/benefits';
import Reasons from 'components/reasons';
import Faqs from 'components/faqs';
import Platforms from 'components/platforms';
import StyledBackgroundSection from 'components/backgroundSection';
import Title from 'components/title';
import Text from 'components/utilities/text';

const Index = ({ data }) => (
  <Layout>
    <Hero hero={data.homeJson.hero} />
    <Platforms items={data.homeJson.platforms} />

    <StyledBackgroundSection
      className="showOnMobile"
      data={data.homeJson.hero_mobile.image.childImageSharp.fluid}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Title as="h2" className="text-light text-center">
          {data.homeJson.hero_mobile.title}
        </Title>
        <Text as="p" className="text-light text-center">
          {data.homeJson.hero_mobile.content}
        </Text>
      </div>
    </StyledBackgroundSection>

    <Cta cta={data.homeJson.cta} />

    <Features feature={data.homeJson.feature} />
    <Benefits benefit={data.homeJson.benefit} />
    <Reasons reason={data.homeJson.reason} />
    <Features feature={data.homeJson.project} />
    <Faqs faqs={data.homeJson.faqs} />
    <Cta cta={data.homeJson.cta2} />
    {/* <div style={{ height: '50vh' }} /> */}
    {/* <IOExample /> */}
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      hero {
        title
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        action
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      hero_mobile {
        title
        content
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      platforms {
        name
        image {
          childImageSharp {
            fluid(maxHeight: 144, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      feature {
        title
        features {
          icon
          headline
          content
          action
        }
      }
      project {
        title
        features {
          icon
          headline
          content
          action
          isRedirect
        }
      }
      benefit {
        title
        content
        benefits {
          icon
          headline
          text
        }
      }
      reason {
        title
        content
        reasons {
          icon
          text
        }
      }
      faqs {
        title
        faqs {
          title
          content
        }
      }
      cta {
        title
        content
        action
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 546) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      cta2 {
        title
        content
        action
      }
    }
  }
`;
